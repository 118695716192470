<div class="header">
    <img src="assets/img/SEP.png" alt="Logo SEP" class="logo-image">
    <h3 class="titleConade" style="color: black;">Sistema del Inventario de la Infraestructura Deportiva Nacional (SIIDN)</h3>
    <img src="assets/img/CONADE.png" alt="Logo CONADE" class="logo-right">
</div>
  
<div class="header-content"> 
  <div *ngIf="isAdmin; else titleBlock" class="dropdown-container">
    <div class="dropdown">
      <button class="dropdown-selector" (click)="toggleDropdown()">
        {{'Inventario'}}
        <mat-icon class="icon-sm" matTooltip="Listado de solicitudes" svgIcon="mat:expand_more"></mat-icon>
      </button>
      <ul *ngIf="showDropdown" class="dropdown-options"> 
        <li *ngFor="let option of options" (click)="onSelectChange(option.value)">
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="isAdmin;" class="menu-item" (click)="Reporte()" style="padding-right: 80px;" >Generar Reporte</div> 
  <div *ngIf="isVerificador || isVerificador" class="menu-item" (click)="Mapa()" style="padding-right: 80px;">Ver Mapa Georreferencial</div>
  <div *ngIf="isSuperUser" class="menu-item" (click)="Usuarios()" style="padding-right: 80px;">Usuarios</div>
  
  <ng-template #titleBlock>
    <div class="title"></div>
  </ng-template>

  <div class="user-container">
    <h2 class="user-name">{{ authenticatedUser }}</h2>
    <button color="warn" mat-mini-fab matTooltip="Cerrar Sesión" class="btnlogout" (click)="logout()">Salir</button>
  </div>
</div> 