
export const environment = {
  production: false,
  Estados: 'http://localhost:9092/api/Estados/ListaEstado',
  Municipio: 'http://localhost:9092/api/Municipio',
  Organismo: 'http://localhost:9092/api/Organismo/ListaOrganismo',
  Acceso: 'http://localhost:9092/api/Loggin/Acceso',
  AccesoClose: 'http://localhost:9092/api/Loggin/Close',
  OrigenRecursos: 'http://localhost:9092/api/OrigenRecurso/ListaOrigenRecurso',
  ListadoInfraestructura: 'http://localhost:9092/api/ListadoInfrestructura',
  PropiedadInmueble: 'http://localhost:9092/api/AcreditaPropiedad/ListaAcreditaPropiedad',
  Disciplina: 'http://localhost:9092/api/Disciplina/ListaDisciplina',
  AddDisciplina: 'http://localhost:9092/api/Disciplina/AgregarDiciplina',
  AreaDeportivo: 'http://localhost:9092/api/AreaDeportiva/ListaAreaDeportivo',
  AddDeportivo: 'http://localhost:9092/api/AreaDeportiva/AgregarAreaDeportivo',
  ServicioDeportivo: 'http://localhost:9092/api/ServicioDeportivo/ListaServicioDeportivo',
  AddServicioDeportivo: 'http://localhost:9092/api/ServicioDeportivo/AgregarServicioDeportivo',
  GuardadoStep1: 'http://localhost:9092/api/SolicitudDatos/CrearSolicitud',
  GuardadoStep2: 'http://localhost:9092/api/OpeInstalacion/CrearOperacionInstalacion',
  ActualizarStep2: 'http://localhost:9092/api/OpeInstalacion/ActualizarOperacionInstalacion',
  AgregarServicio: 'http://localhost:9092/api/ServicioGeneral/AgregarServGeneral',
  EliminarServicio: 'http://localhost:9092/api/ServicioGeneral/EliminarServGeneral',
  AgregarUsuario: 'http://localhost:9092/api/UsuPrincipales/CrearUsuarioPrincipal',
  EliminarUsuario: 'http://localhost:9092/api/UsuPrincipales/EliminarUsuarioPrincipal',
  AgregarPropiedad: 'http://localhost:9092/api/PropiedadInmueble/CrearPropiedadInmueble',
  EliminarPropiedad: 'http://localhost:9092/api/PropiedadInmueble/EliminarPropiedadInmueble',
  DatosTecnicos: 'http://localhost:9092/api/DatosTecnicos/AgregarDatosTecnicos',
  AgregarEquipamiento: 'http://localhost:9092/api/Equipamiento/AgregarEquipamiento',
  EliminarEquipamiento: 'http://localhost:9092/api/Equipamiento/EliminarEquipamiento',
  AgregarInstalacion: 'http://localhost:9092/api/DiagInstalacion/AgregarDiagInstalacion',
  AgregarFoto: 'http://localhost:9092/api/ReporteFoto/AgregarReporteFoto',
  EliminarFoto: 'http://localhost:9092/api/ReporteFoto/EliminarReporteFoto',
  url: 'http://localhost:9092/api/',
  apiUrl : 'http://localhost:9092/api/Usuario/ListaUsuario',
  apiUrlUsuarioCrear : 'http://localhost:9092/api/Usuario/CrearUsuario',
  apiUrlUsuarioEditar : 'http://localhost:9092/api/Usuario/EditarUsuario',
  apiUrlUsuarioEstatus : 'http://localhost:9092/api/Usuario/EstatusUsuario'
};